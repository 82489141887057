.App {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 90vh; 
  width: 100vw;
  text-align: center;
}

.box {
  border: 2px solid white;
  border-radius: 1.5rem;
  width: 85%;
  height: 95%;
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  color: white;
}

.divPara{
  margin: 2rem 0;
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  animation: pulseBorder 5s ease-in-out infinite alternate;
}

p{
  font-size: 1rem;
  width:'90%';
  height:'90%';
  color: white;
  margin: 0;
}

/* .button-85:before { */
  /* content: ""; */
  /* background: linear-gradient(
    45deg,
    #91dff7,
    #F7444E,
    #91dff7,
    #F7444E,
    #91dff7,
    #F7444E,
    #91dff7,
    #F7444E,
    #91dff7
  ); */
  /* position: absolute;
  top: -2px;
  left: -2px; */
  /* background-size: 400%; */
  /* z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px); */
  /* animation: glowing-button-85 15s linear infinite; */
  /* transition: opacity 0.3s ease-in-out;
  border-radius: 10px; */
/* } */

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* .button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
} */

@keyframes pulseBorder {
  0% {
    background-color: yellow;
    color: black;
    border: 0.2rem solid rgb(95, 102, 0);
  }
  20% {
    background-color: rgba(253, 2, 2, 0.63);
    color: rgb(255, 255, 255);
    border: 0.2rem solid rgb(233, 2, 2);
  }
  40% {
    background-color: rgb(96, 93, 255);
    color: black;
    border: 0.2rem solid rgba(4, 0, 78, 0.548);
  }
  60% {
    background-color: rgb(24, 74, 0);
    color: rgb(255, 255, 255);
    border: 0.2rem solid rgb(34, 202, 0);
  }
  80% {
    background-color: rgb(0, 251, 255);
    color: black;
    border: 0.2rem solid rgba(0, 0, 0, 0.514);
  }
  100% {
    background-color: rgb(74, 1, 71);
    color: rgb(255, 255, 255);
    border: 0.2rem solid rgb(183, 0, 255);
  }
}

body {
  margin: 0;
  padding: 0;
  background: #F7F8F3;
  font-weight: bolder;
  font-family: Impact, 'Arial Narrow Bold', sans-serif;
  background-color: black;
  color: white;
}

